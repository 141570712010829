// Helpers
@function calculateRem($size) {
	$remSize: ($size / 16px);
	@return #{$remSize}rem;
}

// Colors
$vda-default: #413745;
$vda-pink: #e20076;
$vda-purple: #660a5e;
$vda-lightGray: #eeeeee;
$vda-red: #e20076;
$vda-grey: #777777;

// Fonts
$vda-defaultFont: "Yantramanav", "Open Sans", sans-serif;

$vda-h1: normal normal 700 calculateRem(80px) / calculateRem(85px) $vda-defaultFont;
$vda-h2: normal normal 500 calculateRem(50px) / calculateRem(62px) $vda-defaultFont;
$vda-h3: normal normal 500 calculateRem(34px) / calculateRem(40px) $vda-defaultFont;
$vda-h4: normal normal 400 calculateRem(28px) / calculateRem(34px) $vda-defaultFont;
$vda-h5: normal normal 500 calculateRem(24px) / calculateRem(30px) $vda-defaultFont;
$vda-h5: normal normal 500 calculateRem(24px) / calculateRem(30px) $vda-defaultFont;
$vda-h6: normal normal 400 calculateRem(20px) / calculateRem(20px) $vda-defaultFont;
$vda-bodyText: normal normal 400 calculateRem(24px) / calculateRem(32px) $vda-defaultFont;
$vda-preHead: normal normal 400 calculateRem(18px) / calculateRem(18px) $vda-defaultFont;

// Distances
$vda-module: 100px;
$vda-products: 50px;
$vda-mobile-breakpoint: 900px;
$vda-navigation-height: 86px;
