﻿@import '../../../css/vars.scss';

.Header {
	border-bottom: 1px solid darken($vda-lightGray, 7.5);
	background: #fff;
	position: fixed;
	top: 0;
	z-index: 8;
	min-height: $vda-navigation-height;
	box-shadow: 0 0 5px rgba(100, 100, 100, .2);

	& > .row {
		@media (max-width: $vda-mobile-breakpoint) {
			flex-wrap: nowrap;
		}
	}

	&__mobile-menu {
		display: none;
		min-height: $vda-navigation-height;
		align-items: center;
		padding-left: 20px;
		cursor: pointer;

		i {
			font-size: 2rem;
		}

		@media (max-width: $vda-mobile-breakpoint) {
			display: flex;
			min-width: 70px;
		}
	}
}

.Logo {
	
	background: url('../../../assets/images/logo.png') 40px center no-repeat;
	text-indent: -9999em;
	background-size: 80px;

	@media (max-width: $vda-mobile-breakpoint) {
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;
		background-position: 0 center;
	}
}
