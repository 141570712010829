﻿@import '../../../css/vars.scss';

.Navigation {
	$navigationRef: &;
	display: none;
	justify-content: flex-end;
	z-index: 7;
	font: $vda-h6;

	@media (max-width: $vda-mobile-breakpoint) {
		position: fixed;
		top: $vda-navigation-height;
		left: 0;
		flex-direction: column;
		background: #fff;
		bottom: 0;
		width: 90%;
		justify-content: flex-start;
		animation: freemium-slide-in-left forwards 100ms;
	}

	@media (min-width: $vda-mobile-breakpoint) {
		display: flex;
	}

	&--is-open {
		display: flex;
		padding-left: 15px;
		max-height: calc(100vh - #{$vda-navigation-height});
		overflow-y: hidden;
	}

	&__item {
		color: $vda-default;
		padding: 30px;

		@media (max-width: $vda-mobile-breakpoint) {
			padding: 10px;
			margin-bottom: 20px;
			font-size: 2rem;
			display: block;
		}

		&:hover,
		&--is-active {
			color: $vda-pink;
			text-decoration: none;
		}
	}

	&__background{
		position: fixed;
		top: $vda-navigation-height;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.5);
		z-index: 3;
		animation: freemium-fade-in forwards 300ms;

		@media(min-width: $vda-mobile-breakpoint){
			display: none;
			visibility: hidden;
		}
	}
}
