﻿.AdvancedSearchInfo {
    font-size: 1.2rem;
    text-align: center;
    margin-top: 12px;

    a {
        color: white;
        text-decoration: none;
    }

    span {
        padding: 6px;
    }

    .rounded-background {
        background-color: rgba(0,0,0,0.2);
        border-radius: 16px;
    }

    i {
        vertical-align: baseline;
    }
}
