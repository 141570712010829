﻿@import '../../../css/vars.scss';

.Footer {
	$footerRef: &;
	padding-top: 30px;
	padding-bottom: 30px;
	background: #413745;
	color: #fff;
	font-size: 1.2rem;

	.col {
		@media (max-width: 767px) {
			flex: 100%;
			margin-bottom: 30px;

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	a {
		color: #fff;
	}

	&__main {
		padding-bottom: 60px;
	}

	&__heading {
		margin-bottom: 10px;
		font: $vda-h4;
	}

	&__list {
		list-style: none;
		padding: 0;
		margin: 0;
		font: $vda-h5;

		&-item{
			font-weight: normal;
		}

		&--is-horizontal {
			display: flex;

			#{$footerRef}__list-item {
				margin-right: 30px;
				font-size: 2rem;
			}
		}
	}

	&__sub-footer {
		font: $vda-preHead;
		padding-top: 30px;
		border-top: 1px solid #fff;
	}
}
