﻿.Home {
	margin-left: -15px;
	margin-right: -15px;

	&__articles {
		display: flex;

		@media (max-width: 1140px) {
			flex-wrap: wrap;
		}
	}
}

.Hero {
	// background: #fff url("~/images/hero.jpg") center center no-repeat;
	background: url("../../../src/assets/images/hero.jpg") 40px center no-repeat;
	background-size: cover;
	color: #fff;
	position: relative;
	padding: 150px 0;
	text-align: center;
	min-height: 700px;

	a,
	li {
		color: black;
	}

	@media (max-width: 1140px) {
		word-break: break-word;
		hyphens: auto;
		padding: 75px 0;
		min-height: auto;
	}

	&__subtitle {
		font-size: 3.5rem;
		margin-bottom: 20px;

		@media (max-width: 1140px) {
			font-size: 2rem;
		}

		@media (max-width: 400px) {
			font-size: 1.25rem;
		}
	}

	&__title {
		font: $vda-h1;
		max-width: 80%;
		margin: 0 auto;

		@media (max-width: 993px) {
			font: $vda-h2;
			font-weight: 700;
		}

		@media (max-width: 769px) {
			font: $vda-h3;
			font-weight: 700;
		}

		@media (max-width: 420px) {
			font: $vda-h4;
			font-weight: 700;
		}

		@media (max-width: 350px) {
			font: $vda-h5;
			font-weight: 700;
		}
	}
}
