﻿.Allergen__table {
    width: 100%;

    td {
        padding: 10px;
        padding-left: 25px;
        font-weight: 500;

        .red {
            color: $vda-red;
        }

        .grey {
            color: $vda-grey;
        }
    }

    th {
        padding: 5px;
    }

    td:not(:first-child) {
        border-left: 1px solid #d9d7da;
        text-align: center;
        font-size: smaller;
        padding: 10px 20px;
    }

    th:not(:first-child) {
        border-left: 1px solid #d9d7da;
    }

    tr:nth-child(2n + 1) {
        background: #eee;
    }

    th {
        color: $vda-grey;
        font-weight: 400;
        font-size: 1rem;
        height: 42px;
        background-color: white;
        text-align: center;
    }

    > thead th{
        position: sticky;
        top: 86px; // size of site header
        border-bottom: 1px solid #d9d7da;
    }

    .small {
        font-size: small;
    }

    .childRow {
        td {
            color: $vda-grey;
            font-weight: 400;
            font-size: 1rem;
        }
    }

    .expandable {
        cursor: pointer;

        &:before {
            font-family: FontAwesome, sans-serif;
            font-size: 1rem;
            content: '\F055';
            color: #e20076;
            margin-left: -1.25rem;
            padding: 0.25rem;
            transform: translateY(-50%);
        }
    }

    .expanded {
        &:before {
            content: '\F056' !important;
        }
    }
}

.c_kan-inneholde {
    margin: 0.5rem 0;
    padding: 1rem;
    background: #eee;
    font-size: 1rem;

    .header {
        color: $vda-grey;
    }
}
