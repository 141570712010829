﻿.CompanyListItem {
	$spacer: 30px;
	$companyListItemRef: &;

	width: calc(33% - #{$spacer});
	display: flex;
	flex-direction: column;
	margin: 0 $spacer/2 $spacer;
	padding: 10px;
	cursor: pointer;
	color: $vda-default;
	//opacity: 0;
	//animation: freemium-slide-in-bottom forwards 300ms ease-in-out;
	//@for $i from 1 to 100 {
	//  &:nth-child(#{$i}) { animation-delay: $i * 0.01s; }
	//}
	&:last-of-type {
		margin-right: 0;
	}

	&:hover {
		color: $vda-default;
		text-decoration: none;

		#{$companyListItemRef}__title {
			color: $vda-pink;
		}

		#{$companyListItemRef}__image {
			opacity: 0.9;
		}
	}

	&__image {
		width: 100%;
        max-height: 250px;
		height: auto;
		opacity: 1;
	}

	&__title {
		font: $vda-h5;
        text-align: center;
	}

	&__subtitle{
		font: $vda-h6;
        text-align: center;
	}

	@media (max-width: 1300px) {
		width: calc(33% - #{$spacer});
	}

	@media (max-width: 900px) {
		width: calc(50% - #{$spacer});
	}

	@media (max-width: 700px) {
		width: 100%;
		margin: 0;
	}
}
