﻿.ProductSearch {
	&__result {
		&-heading {
			font: $vda-h2;
			margin-top: $vda-products;

			@media (max-width: $vda-mobile-breakpoint + 1px) {
				font: $vda-h3;
				margin-top: $vda-products / 2;
			}
		}

		&-count {
			font-size: 1.3rem;
		}
	}

	&__filter-button {
		margin-top: 10px;

		@media (min-width: $vda-mobile-breakpoint) {
			display: none;
		}
	}
}

.row-wrap-fix {
	width:auto;
}
