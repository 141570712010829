.About {
  padding: $vda-products 0;

  h1{
    font: $vda-h1;
  }

  h2{
    font: $vda-h2;
  }

  @media (max-width: 992px) {
    h1{
      font: $vda-h2;
    }

    h2{
      font: $vda-h3;
    }
  }
}
