// @import "functions";
// @import "variables";
// @import "mixins";

// @import "reboot";
// @import "grid";
// @import "utilities";

@import 'bootstrap/dist/css/bootstrap.min.css';

@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css?family=Yantramanav:400,500,700');

@import 'vars.scss';


@import '../Components/PageLoadSpinner/PageLoadSpinner.scss';
@import '../Components/ProductSearch/ProductListItem.scss';
@import '../Components/ProductSearch/ProductList.scss';
@import '../Components/ProductSearch/ProductSearch.scss';
@import '../Components/ProductSearchFilter/ProductSearchFilter.scss';
@import '../Components/ProductPage/ProductPage.scss';
@import '../Components/ProductPage/AllergenTable.scss';
@import '../Components/Home/Home.scss';
@import '../Components/Company/Company.scss';
@import '../Components/Company/CompanyList.scss';
@import '../Components/Company/CompanyListItem.scss';
@import '../Components/About/About.scss';
@import '../Components/Contact/Contact.scss';
@import '../Components//Chat/Chat.scss';

//Utility

@import '../Components/Utility/Button/Button.scss';
@import '../Components/Utility/Footer/Footer.scss';
@import '../Components/Utility/Header/Header.scss';
@import '../Components/Utility/Article/Article.scss';
@import '../Components/Utility/Navigation/Navigation.scss';
@import '../Components/Utility/SearchBar/SearchBar.scss';
@import '../Components/Utility/AdvancedSearchInfo/AdvancedSearchInfo.scss';

.crawlerskiplink, .initial-loading-spinner {
    display: none;
}

body {
    font: $vda-bodyText;
    color: $vda-default;
    padding-top: $vda-navigation-height;
    overflow-y: scroll;

    &.is-blocked {
        overflow-y: hidden;

        @media(min-width: $vda-mobile-breakpoint){
            overflow-y: auto;
        }
    }
}

img {
    max-width: 100%;
    height: auto;
}

html, body {
    width: 100%;
}

.container {
    padding-left: 0;
    padding-right: 0;
}


.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
    width:100%;
    padding-right:15px;
    padding-left:15px;
    margin-right:auto;
    margin-left:auto;

}

.product-image{
    min-height: 214px;
    position: relative;
    margin-bottom: 10px;
}

.Spinner {
    min-height: calc(100vh - #{$vda-navigation-height});
    background: #fff;
    position: relative;
    margin: auto;
    
    &__inner {
        animation: spinner-rotation 750ms infinite linear;
        height: 60px;
        width: 60px;
        border: 8px solid #e20076;
        border-right-color: transparent;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -30px 0 0 -30px;
        z-index: 5;
    }

    &--small {
        min-height: 140px;
    }
}

@keyframes spinner-rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }


@keyframes freemium-slide-in-left {
    from {
        opacity: 0;
        transform: translateX(-100%);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes freemium-slide-in-bottom {
    from {
        opacity: 0;
        transform: translateY(5%);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes freemium-slide-in-top {
    from {
        transform: translateY(-100%);
        margin-bottom: -136px;
    }

    to {
        transform: translateY(0);
        margin-bottom: 0;
    }
}

@keyframes freemium-fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
