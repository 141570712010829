﻿.Company {
	@media (max-width: 700px) {
		text-align: center;
	}

	&__result {
		&-heading {
			font: $vda-h2;
			margin-top: $vda-products;
		}

		&-count {
			font-size: 1.3rem;
		}
	}

	&__filter-button {
		margin-top: 10px;

		@media (min-width: 800px) {
			display: none;
		}
	}
}
