﻿@import '../../css/vars.scss';

.ProductListItem {
	$spacer: 30px;
	$productListItemRef: &;

	width: calc(20% - #{$spacer});
	display: flex;
	flex-direction: column;
	text-align: center;
	margin: 0 $spacer/2 $spacer;
	padding: 10px;
	cursor: pointer;
	color: #413745;
	font-size: 1rem;
	min-width: 180px;
	//opacity: 0;
	//animation: freemium-slide-in-bottom forwards 300ms ease-in-out;
	//@for $i from 1 to 100 {
	//  &:nth-child(#{$i}) { animation-delay: $i * 0.025s; }
	//}
	&:last-of-type {
		margin-right: 0;
	}

	&:hover {
		color: #413745;
		text-decoration: none;

		#{$productListItemRef}__title {
			color: $vda-pink;
		}

		#{$productListItemRef}__image {
			opacity: 0.9;
		}
	}

	&__image {
		opacity: 1;
		max-height: 100%;
		max-width: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&__title {
		font: $vda-h5;
		word-wrap: break-word;
	}

	&__icons {
		display: flex;
		justify-content: center;
		margin: 15px 0;
		flex-wrap: wrap;
	}

	&__icon {
		height: 35px;
		width: 35px;
		margin: 10px 5px 0 5px;
	}

	&__supplier, &__quantity{
		font: $vda-preHead;
	}

	@media (max-width: 1300px) {
		width: calc(33% - #{$spacer});
	}

	@media (max-width: 900px) {
		width: calc(50% - #{$spacer});
	}

	@media (max-width: 500px) {
		width: 100%;
		margin: 0;
	}
}
