﻿.ProductSearchFilter {
	padding: 25px;
	padding-top: 0;
	background: #fff;
	min-width: 245px;
	display: none;
	border-right: 1px solid $vda-lightGray;
	font-size: 1rem;
	z-index: 6;

	@media (min-width: $vda-mobile-breakpoint) {
		display: block;
	}

	&--is-open {
		display: block;
	}

	@media (max-width: $vda-mobile-breakpoint) {
		height: calc(100vh - #{$vda-navigation-height});
		max-width: 90%;
        top: #{$vda-navigation-height};
		position: fixed;
		overflow-y: scroll;
		animation: freemium-slide-in-left forwards 300ms;
		padding-top: 20px;
	}

	&::-webkit-scrollbar-track {
		background-color: #fff;
	}

	&::-webkit-scrollbar {
		width: 3px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $vda-lightGray;
	}

	&__close {
		cursor: pointer;
		font-size: 2rem;

		@media (min-width: $vda-mobile-breakpoint) {
			display: none;
		}
	}

	&__category {
		$categoryRef: &;
		padding: 20px 0;
		border-top: 1px solid $vda-lightGray;

		&:nth-of-type(2) {
			border-top: none;
		}

		&-heading {
			font: $vda-h6;
			position: relative;
			cursor: pointer;

			&:hover {
				color: $vda-pink;
			}

			&:after {
				font: normal normal normal 14px/1 FontAwesome;
				content: "\f078";
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		&-heading + &-list {
			margin-top: 10px;
		}

		&-list {
			list-style: none;
			padding: 0;
			margin: 0;
			display: none;
		}

		&-list-item {
			margin-bottom: 5px;
			cursor: pointer;
			position: relative;
			text-transform: capitalize;

			&:before {
				font: normal normal normal 20px/1 FontAwesome;
				content: "\F1DB";
				color: #000;
				margin-right: 10px;
			}

			&--is-selected {
				color: $vda-pink;

				&:before {
					content: "\f055";
					color: $vda-pink;
				}
			}

			@media (hover: hover) {
				&:hover,
				&:hover:before {
					content: "\f055";
					color: $vda-pink;
				}
			}
		}

		&--is-open {
			& #{$categoryRef}-list {
				display: block;
			}

			& #{$categoryRef}-show-more {
				display: inline-block;
			}

			& #{$categoryRef}-heading {
				&:after {
					transform: rotate(180deg);
				}
			}
		}

		&-show-more {
			display: none;
			color: $vda-pink;
			cursor: pointer;
			padding-bottom: 2px;
			font-weight: bold;
			border-bottom: 1px dashed transparent;

			&:hover {
				border-color: $vda-pink;
			}
		}
	}

	&__reset {
		margin-left: -25px;
		margin-right: -25px;
	}

	&__background{
		position: fixed;
		top: $vda-navigation-height;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.5);
		z-index: 3;
		animation: freemium-fade-in forwards 300ms;

		@media(min-width: $vda-mobile-breakpoint){
			display: none;
			visibility: hidden;
		}
	}
}
