﻿.Article {
	$articleRef: &;
	flex: 1;
	padding: $vda-module 0;
	position: relative;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	background-color: #fff;

	a {
		color: inherit;
		text-decoration: none;
	}

	@media (max-width: 1140px) {
		flex: 100%;
		padding: 10% 5%;
		font-size: 1rem;
		word-break: break-word;
		hyphens: auto;
	}

	&__container {
		margin: 0 auto;

		@media (max-width: 1140px) {
			max-width: 100%;
		}
	}

	&__pretitle {
		text-transform: uppercase;
		font: $vda-h6;
		margin: 0;
	}

	&__title {
		font: $vda-h2;
		margin: 0;
	}

	&__content{
		margin: 35px 0;
	}

	&--is-call-to-action {
		text-align: center;

		#{$articleRef}__container {
			max-width: 930px;

			@media (max-width: 1140px) {
				max-width: 80%;
			}

			@media (max-width: 955px) {
				max-width: 90%;
			}

			@media (max-width: 850px) {
				max-width: 100%;
			}
		}

		#{$articleRef}__title {
			font: $vda-h1;

			@media (max-width: 765px) {
				font: $vda-h2;
				font-weight: 700;
			}

			@media (max-width: 480px) {
				font: $vda-h3;
				font-weight: 700;
			}

			@media (max-width: 325px) {
				font: $vda-h4;
				font-weight: 700;
			}
		}

		#{$articleRef}__content {
			font: $vda-h4;

			@media (max-width: 765px) {
				font: $vda-h5;
				font-weight: normal;
			}

			@media (max-width: 480px) {
				font: $vda-h6;
				font-weight: normal;
			}
		}
	}
}

.Home__articles.Home__articles--is-blogposts {
	flex-wrap: wrap;

	.Article {
		flex: 0 0 50%;
		min-height: 600px;

		@media (max-width: 1060px) {
			flex: 0 0 100%;
			min-height: 300px;
		}

		@media (max-width: 660px) {
			min-height: 200px;
		}

		@media (max-width: 410px) {
			font: $vda-h6;
			min-height: 150px;
		}

		&:last-of-type {
			flex: 0 0 100%;
			text-align: center;

			@media (max-width: 2400px) {
				padding-top: 150px;
				padding-bottom: 150px;
			}
		}

		&__container {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 60%;

			@media (max-width: 2400px) {
				width: 65%;
			}

			@media (max-width: 2250px) {
				width: 70%;
			}

			@media (max-width: 2050px) {
				width: 75%;
			}

			@media (max-width: 1925px) {
				width: 80%;
			}

			@media (max-width: 1850px) {
				width: 85%;
			}

			@media (max-width: 1700px) {
				width: 90%;
			}

			@media (max-width: 1600px) {
				width: 65%;
			}

			@media (max-width: 1600px) {
				width: 70%;
			}

			@media (max-width: 1405px) {
				width: 75%;
			}

			@media (max-width: 1405px) {
				width: 80%;
			}

			@media (max-width: 1230px) {
				width: 85%;
			}

			@media (max-width: 1150px) {
				width: 90%;
			}
		}

		&__title {
			margin-bottom: 0;

			@media (max-width: 1600px) {
				font: $vda-h3;
				font-weight: 700;
			}

			@media (max-width: 550px) {
				font: $vda-h4;
				font-weight: 700;
			}

			@media (max-width: 450px) {
				font: $vda-h5;
				font-weight: 700;
			}

			@media (max-width: 385px) {
				font: $vda-h6;
				font-weight: 700;
			}
		}

		&__content {
			margin-top: 10px;
		}

		&__call-to-action {
			margin-top: 30px;
		}
	}
}
