﻿.Product {
    $productRef: &;
    margin-top: 60px;
    font-size: 1.5rem;

    @media (max-width: 500px) {
        font-size: 1rem;
    }

    h3 {
        font: $vda-h3;
        font-weight: normal;

        + #{$productRef}__about-details {
            margin-top: $vda-module;
        }
    }

    &__main {
        @media (max-width: 766px) {
            text-align: center;
        }

        .col {
            @media (max-width: 767px) {
                order: 2;
                flex: 100%;
            }
        }

        #{$productRef}__image.col {
            @media (max-width: 767px) {
                order: 1;
            }
        }
    }

    &__title {
        font: $vda-h2;
    }

    &__title,
    &__info {
        margin: 0;
        line-height: 1.2;
    }

    &__info {
        font-weight: normal;
        font-size: 1.5rem;
    }

    &__extrainfo {
        font-weight: normal;
        font-size: 1.25rem;
    }

    &__allergens {
        margin-top: 40px;

        &-list {
            font-size: 1.5rem;
            padding: 0;
            margin: 0;
        }

        &-list-item {
            list-style: none;

            &:before {
                font-family: FontAwesome, sans-serif;
                content: '\f071';
                margin-right: 10px;
            }

            &--is-empty:before {
                content: '\f058';
            }
        }
    }

    &__may-contain {
        font-size: 1.3rem;
        margin: 30px 0;
    }

    &__icons {
        display: flex;
        margin: 15px 0;
    }

    &__icon {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background: #000;
        margin-right: 30px;
    }

    &__image {
        text-align: center;

        @media (max-width: 763px) {
            max-width: 80%;
            margin: 0 auto;
        }
    }

    &__supplier-link {
        color: $vda-pink;
        font: $vda-h4;
        display: block;
        margin-top: 10px;
    }

    &__about-details {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
    }

    .Accordion {
        + #{$productRef}__disclaimer {
            margin-top: 60px;
        }
    }

    &__about,
    &__more-products {
        padding: 60px 0;
        display: block;
    }

    &__more-products {
        @media (max-width: 700px) {
            text-align: center;
        }

        h2 {
            font: $vda-h2;

            @media (max-width: 700px) {
                width: 100%;
            }

            @media (max-width: 400px) {
                font: $vda-h3;
            }
        }
    }

    &__producer {
        background: $vda-lightGray;

        &-image {
            @media (max-width: 767px) {
                display: none;
            }
        }

        button {
            font-size: 1.25rem;
        }

        &-phone,
        &-title,
        &-mail,
        &-description {
            margin: 0;
            padding: 0;
        }

        &-contact {
            a {
                color: $vda-pink;
            }

            + a {
                margin-top: 20px;
                display: inline-block;
            }
        }

        &-description-container {
            padding: $vda-module 0;
            background: $vda-lightGray;
            position: relative;

            @media (max-width: 580px) {
                padding: $vda-module 20px;
            }
        }

        &-hero {
            height: 650px;
            background-color: $vda-default;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            box-shadow: inset 0 -3px 5px rgba(100, 100, 100, 0.2);

            @media (max-width: 1250px) {
                height: 400px;
            }

            @media (max-width: 992px) {
                height: 200px;
            }

            > .container {
                position: relative;
                height: 100%;

                #{$productRef}__producer-name {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }

        &-name {
            color: #fff;
            font: $vda-h2;
            text-align: center;
        }

        &-logo {
            max-width: 80%;
            max-height: 80%;

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                width: 220px;
            }
        }

        &-logo-container {
            $logoContainerSize: 230px;
            position: absolute;
            bottom: -($logoContainerSize / 2);
            right: 0;
            height: $logoContainerSize;
            width: $logoContainerSize;
            border-radius: 50%;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            z-index: 2;
            transition: right 100ms ease-in-out;
            overflow: hidden;

            @media (max-width: 992px) {
                height: $logoContainerSize / 1.5;
                width: $logoContainerSize / 1.5;
                bottom: -(($logoContainerSize / 2) / 1.5);
            }

            @media (max-width: 800px) {
                right: 50%;
                transform: translateX(50%);
            }
        }

        &-title {
            font: $vda-h2;
            margin-bottom: 30px;

            @media (max-width: 400px) {
                font: $vda-h3;
            }
        }

        &-description {
            font: $vda-h4;
            color: $vda-default;

            @media (max-width: 400px) {
                font: $vda-h5;
                font-weight: normal;
            }
        }

        &-web {
            margin-top: 30px;

            > a {
                color: $vda-pink;
            }
        }

        &--is-condensed {
            padding: $vda-module 0;
            margin-left: -30px;
            margin-right: -30px;

            @media (max-width: 536px) {
                text-align: center;
            }

            #{$productRef}__producer {
                &-contact-logo {
                    min-width: 135px;
                    max-width: 135px;
                }

                &-title {
                    margin-bottom: 10px;
                }

                &-description {
                    margin-top: 20px;

                    + #{$productRef}__producer-read-more {
                        margin-top: 20px;
                        display: inline-block;
                    }
                }
            }
        }
    }

    &__table {
        width: 100%;

        td {
            padding: 10px 20px;
        }

        tr:nth-child(2n + 1) {
            background: #eee;
        }
    }

    &__disclaimer {
        font: $vda-preHead;
        margin-bottom: 60px;
    }

    &__labels {
        display: flex;
        padding: 0;
        margin-top: 35px;

        @media (max-width: 766px) {
            justify-content: center;
        }
    }

    &__label {
        list-style: none;
        margin: 0;
        padding: 0;
        margin-right: 25px;

        &-image {
            width: 50px;
            height: 50px;
        }
    }
}

.Accordion {
    $accordionRef: &;
    border: 1px solid #d9d7da;
    border-left: none;
    border-right: none;
    font-size: 1rem;
    padding: 30px 10px;

    $accordion-spacer: 40px;
    width: calc(50% - (#{$accordion-spacer}/ 2));
    margin-right: $accordion-spacer;

    @media (max-width: 991px) {
        width: 100%;
        margin-right: 0;
        border-top: none;

        &:first-child {
            border-top: 1px solid #d9d7da;
        }
    }

    &:nth-child(2n) {
        margin-right: 0;
    }

    &:nth-child(1n + 3) {
        border-top: none;
    }

    img {
        max-width: 100%;
    }

    &__header {
        font: $vda-h3;
        position: relative;
        cursor: pointer;

        @media (max-width: 335px) {
            font: $vda-h4;
        }

        &:hover {
            color: $vda-pink;
        }

        &:after {
            font-family: FontAwesome, sans-serif;
            font-size: 2.5rem;
            content: '\f055';
            color: $vda-pink;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
        }
    }

    &__content {
        margin-top: 30px;
        display: none;
        word-wrap: break-word;
        font: $vda-h6;
    }

    &__info-block {
        margin-bottom: 35px;
        line-height: 24px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &--is-open {
        #{$accordionRef}__header {
            color: $vda-pink;

            &:after {
                content: '\f056' !important;
            }
        }

        #{$accordionRef}__content {
            display: block;
        }
    }
}
