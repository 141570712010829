.chat-container {
    position: fixed; 
    right: 1rem;
    bottom: 0;    

    @media (max-width: 480px) {
       width: 90%
      }
    width: 28rem;
    height: 40rem;
    border-radius: 1rem 1rem 0 0;
    box-shadow: 0 0 5px rgba(100, 100, 100, 0.2);
    display: none;
    z-index: 9999;

    .chat {
        width: 100%;
        height: 93%;

        .chat-header {
            width: 100%;
            height: 15%;
            background: #6A2117;
            color: #FFE3C1;
            border-radius: 1rem 1rem 0 0; 
            display: flex;

            .personligheter {
                width: 15rem;
                height: 4rem;
                background: white;
                border: 2px solid #DCDCDC;
                position: absolute;
                left: -15rem;
                border-radius: 1rem;
                font-size: 2.5rem;
                display: none;

                .center-container {

                    span {
                        margin-top: 1rem;
                    }
                }
            }

            .logo {
                height: 4rem;
                width: 4rem;
                background: white;
                border: 2px solid #DCDCDC;
                border-radius: 50%;
                margin: 1rem;
                align-items: center;
                display: flex;
                cursor: pointer;

                .avatar {
                    padding-top: 7px;
                    padding-left: 2px;
                    font-size: 2.5rem;
                }

                img {
                    padding: 6px;
                }
            }

            .header {
                width: 70%; 
                height: 100%;
                position: relative;
            }

            .chevron {
                width: 10%;
                font-size: 1rem;
                cursor: pointer;
            }
        }

        .chat-window {
            width: 100%;
            height: 85%;
            background: white;
            overflow-y: scroll;

            .loading-container {
                width: 70%;
                background: #FFF8D1;
                margin-left: 1rem;
                margin-top: 1rem;
                border-radius: 0.5rem;
                padding: 1rem;
                position: relative;

                .loading {
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    width: auto;                    
                  }
                  
                  .loading__dot {
                    animation: dot ease-in-out 1s infinite;
                    background-color: rgb(205, 11, 11);
                    display: inline-block;
                    height: 10px;
                    border-radius: 50%;
                    margin-left: 10px;
                    width: 10px;
                  }
                  
                  .loading__dot:nth-of-type(2) {
                    animation-delay: 0.2s;
                  }
                  
                  .loading__dot:nth-of-type(3) {
                    animation-delay: 0.3s;
                  }

            }

            .initial-message {
                font-size: 1rem;
                background: #f7f4e6;
                width: calc(100% - 2rem);
                border-radius: 0.5rem;
                margin-left: 1rem;
                margin-right: 1rem;
                padding: 1rem;
                padding-right: 2rem;
                margin-top: 1rem;
                position: relative;

                .info {
                    width: 1.8rem;
                    height: 1.8rem;
                    right: 1rem;
                    top: 1rem;
                    background: gray;
                    border-radius: 50%;
                    color: white;
                    position: absolute;
                    font-size: 1.5rem;
                    text-align: center;
                }
            }

            .receiver {
                font-size: 1rem;
                background: #FFF8D1;
                width: 70%;
                border-radius: 0.5rem;
                margin-left: 1rem;
                padding: 1rem;
                margin-top: 1rem;
            }

            .sender {
                font-size: 1rem;
                background: #FFE3C1;
                width: 70%;
                border-radius: 0.5rem;
                margin-left: calc(30% - 1rem);
                margin-right: 1rem;
                padding: 1rem;
                margin-top: 1rem;
            }
        }
    }

    .chat-text-area {
        width: 100%;
        height: 7%;
        display: flex;
        border-top: 1px solid gray;

        form {
            width: 100%;
            display: flex;
        }

        input {
            width: 70%;
            height: 100%;
            padding: 0.5rem;
            border: none;
            outline: none;
            resize: none;
            font-size: 1rem;
        }

        button {
            width: 30%;
            height: 100%;
            background: #D42C32;
            border: none;
            color: white;
        }
    }
}

.chat-logo {
    position: fixed;
    right: 2rem;
    bottom: 2rem;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    backface-visibility: visible !important;
    animation: flip 2s ease infinite;
    cursor: pointer;
    display: block;
}


@keyframes flip {
	0% {
		transform: perspective(400px) rotateY(0);
		animation-timing-function: ease-out;
	}
	40% {
		transform: perspective(400px) translateZ(150px) rotateY(170deg);
		animation-timing-function: ease-out;
	}
	50% {
		transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
		animation-timing-function: ease-in;
	}
	80% {
		transform: perspective(400px) rotateY(360deg) scale(.95);
		animation-timing-function: ease-in;
	}
	100% {
		transform: perspective(400px) scale(1);
		animation-timing-function: ease-in;
	}
}
  
  @keyframes dot {
    0% { background-color: rgb(102, 10, 94); transform: scale(1); }
    50% { background-color: rgb(226, 0, 118); transform: scale(1.3); }
    100% { background-color: rgb(102, 10, 94); transform: scale(1); }
  }

