.loading-spinner {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: #fff;
}
.loading-spinner__inner {
	-webkit-animation-duration: 0.75s;
	-moz-animation-duration: 0.75s;
	animation-duration: 0.75s;
	-webkit-animation-iteration-count: infinite;
	-moz-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-name: rotate-forever;
	-moz-animation-name: rotate-forever;
	animation-name: rotate-forever;
	-webkit-animation-timing-function: linear;
	-moz-animation-timing-function: linear;
	animation-timing-function: linear;
	height: 60px;
	width: 60px;
	border: 8px solid $vda-pink;
	border-right-color: transparent;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -30px 0 0 -30px;
	z-index: 5;
}
