﻿.SearchBar {
    padding: 40px 0 12px 0;
    font-size: 1.5rem;
    position: relative;
    width: 100%;
    z-index: 3;

    &__input {
        padding: 20px 50px 20px 70px;
        border-radius: calc(92px / 2);
        border: none;
        outline: none;
        width: 100%;
        color: $vda-default;

        ::placeholder {
            color: $vda-default;
        }
    }

    &__icon.fa {
        font-size: 1.7rem;
        position: absolute;
        top: 46%;
        transform: rotateY(180deg) translateY(-50%);
        color: $vda-default;

        &.fa-search {
            left: 40px;
        }

        &.fa-times {
            right: 40px;
            cursor: pointer;
        }
    }

    &__suggestions {
        position: absolute;
        background: #fff;
        width: 85%;
        left: 50%;
        transform: translateX(-50%);
        margin: 0;
        padding: 0;
        font-size: 1rem;
        margin-top: 15px;
        box-shadow: 0 5px 10px rgba(75, 72, 72, 0.2);
    }

    &__suggestion {
        list-style: none;
        background: #fff;
        border-bottom: 1px solid $vda-lightGray;
        text-align: left;
        cursor: pointer;

        &:hover {
            background: $vda-lightGray;
            border-color: transparent;
            transition: background 100ms ease-in-out;

            a:before {
                left: 25px;
            }
        }

        a {
            display: block;
            padding: 20px 0 20px 50px;
            color: $vda-default;
            text-decoration: none;
            position: relative;

            &:before {
                content: "\f101";
                font-family: FontAwesome, sans-serif;
                position: absolute;
                top: 50%;
                left: 20px;
                transform: translateY(-50%);
                transition: left 100ms ease-in-out;
            }
        }
    }

    &--animated {
        margin-bottom: -152px;
        transform: translateY(-152px);
        animation: freemium-slide-in-top forwards 300ms ease-in-out;
        animation-delay: 300ms;
    }
}

.col-relative-fix {
    position:relative;
}
