﻿@import '../../../css/vars.scss';

.Button {
	background: $vda-pink;
	color: #fff;
	padding: 20px 30px;
	outline: none;
	border: none;
	cursor: pointer;
	transition: background 100ms ease-in-out;
	font: $vda-preHead;
	font-weight: bold;

	&--is-rounded {
		border-radius: 30px;
	}

	&--is-fullwidth {
		width: 100%;
	}

	&--is-caps {
		text-transform: uppercase;
	}

	&:hover {
		background: darken($vda-pink, 15);
	}
}

a.Button{
	display: inline-block;
	color: #fff;

	&:visited,
	&:link{
		color: #fff;
	}
}