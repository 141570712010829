.Contact {
	padding: $vda-products 0;

	&__map {
		margin-left: -30px;
		margin-right: -30px;
		max-width: none;

		@media (max-width: 768px) {
			max-height: 300px;
		}

		& > iframe {
			width: 100%;

			@media (max-width: 768px) {
				max-height: 300px;
			}
		}
	}

	h1 {
		font: $vda-h1;
		margin-bottom: $vda-products;

		@media (max-width: 620px) {
			font: $vda-h2;
			font-weight: bold;
			margin-left: 15px;
		}
	}

	&__box {
		padding: 30px;
		background: $vda-lightGray;

		@media (max-width: 768px) {
			margin-bottom: 20px;
		}

		&-header {
			font: $vda-h3;
			margin-bottom: 10px;
		}

		&-bit {
			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 0;
			}

			p {
				margin: 0;
				font: $vda-h5;
				font-weight: normal;
			}

			&-header {
				font: $vda-h5;
				font-weight: bold;
				margin: 0;
			}

			a{
				color: $vda-pink;
			}
		}
	}
}
